import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 * @param  {String} creditApplicationId
 */
export class ValidacionService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
  }

  /**
   * @returns {String}
   */
  get id () {
    return this._creditApplicationId
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send () {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/kyc?creditApplicationId=${self.id}`,
    }))
  }
} 
