import Service from '../Service'
import { LandingV4Instance } from './Instance'

 /**
 * @typedef {Object} PldData
 * @property {String} creditApplicationId
 * @property {Boolean} publicPosition
 * @property {Boolean} directRelationship
 */

/**
 * @class
 * @param  {String} creditApplicationId
 */

export class PldService extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
    }

/**
 * @param  {String} landingApplicationId
 */
    setValidation(publicPosition, directRelationship) {
        this._publicPosition = !!publicPosition
        this._directRelationship = !!directRelationship
    }

    /**
   * @returns {PldData}
   */
    get data () {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            publicPosition: this._publicPosition,
            directRelationship: this._directRelationship
        }
    }
    
  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
        send () {
            const self = this
            return this._requestWrapper(LandingV4Instance({
                method: 'PUT',
                url: '/kyc/peps',
                data: self.data
            }))
        }  
}